import { Box, Container, Link, Typography } from "@mui/material";

import adjustmentsThumbnail from "./adjustmentsThumbnail.png";

export default function AdjustmentsModulePlaceholder() {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
          textAlign: "center",
          padding: 3,
        }}
      >
        <Typography
          variant="h3"
          color="primary"
          gutterBottom
          component="div"
          sx={{ mt: 2 }}
          fontWeight="bold"
        >
          Adjustments
        </Typography>

        <Box marginY={4} maxWidth="800px">
          <img src={adjustmentsThumbnail} width="100%" />
        </Box>

        <Typography
          variant="h6"
          gutterBottom
          component="div"
          fontSize={36}
          fontWeight="bold"
        >
          Want access to all of these powerful tools? Join True Footage!
          We&apos;re hiring! Visit{" "}
          <Link
            href="https://truefootage.tech/join-us"
            target="_blank"
            rel="noreferrer"
            color="primary"
            style={{ textDecoration: "none" }}
          >
            www.truefootage.tech/join-us
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}
