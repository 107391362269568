import { LockPerson } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

interface ModulePlaceholderProps {
  pageName: string;
}

export default function ModulePlaceholder({
  pageName,
}: ModulePlaceholderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
        textAlign: "center",
        padding: 3,
      }}
    >
      <Typography variant="h4" gutterBottom component="div" sx={{ mt: 2 }}>
        {pageName}
      </Typography>

      <LockPerson sx={{ fontSize: 60, color: "primary.main" }} />

      <Typography variant="h6" gutterBottom component="div">
        Employee Access Only
      </Typography>

      <Typography variant="body1" color="text.secondary">
        This module is restricted to True Footage employees only.
      </Typography>
    </Box>
  );
}
