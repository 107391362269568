import { Gite } from "@mui/icons-material";

import BoundariesHeader from "~/boundaries/components/Header/BoundariesHeader";
import AdjustmentsModulePlaceholder from "~/common/components/ModulePlaceholder/AdjustmentsModulePlaceholder";
import ModulePlaceholder from "~/common/components/ModulePlaceholder/ModulePlaceholder";
import { ValuationProcessStep } from "~/common/state/valuationProcessState";
import { SiteConfiguration } from "./domain/SiteConfiguration";
import { externalNavigationButtons } from "./externalNavigationButtons";

export default {
  header: {
    icon: <Gite />,
    title: "TrueTracts (public)",
    components: {
      [ValuationProcessStep.Sessions]: null,
      [ValuationProcessStep.Boundaries]: <BoundariesHeader />,
      [ValuationProcessStep.CompRecommendation]: null,
      [ValuationProcessStep.CompRec]: null,
      [ValuationProcessStep.MarketAnalysis]: null,
      [ValuationProcessStep.Adjustments]: null,
      [ValuationProcessStep.Reconciliation]: null,
      [ValuationProcessStep.Export]: null,
    },
  },
  navBar: {
    buttons: externalNavigationButtons,
  },
  body: {
    componentOverrides: {
      [ValuationProcessStep.Sessions]: null,
      [ValuationProcessStep.Boundaries]: null,
      [ValuationProcessStep.CompRecommendation]: (
        <ModulePlaceholder pageName="Comp Recommendation" />
      ),
      [ValuationProcessStep.CompRec]: <ModulePlaceholder pageName="Comp Rec" />,
      [ValuationProcessStep.MarketAnalysis]: null,
      [ValuationProcessStep.Adjustments]: <AdjustmentsModulePlaceholder />,
      [ValuationProcessStep.Reconciliation]: (
        <ModulePlaceholder pageName="Reconciliation" />
      ),
      [ValuationProcessStep.Export]: <ModulePlaceholder pageName="Export" />,
    },
  },
  modules: {
    boundaries: {
      showResidualData: false,
      showZipcodeOverlay: false,
      heatmap: {
        resolution: 200,
        filterFactor: 6,
        desiredHomeSales: 15000,
      },
    },
  },
} satisfies Readonly<SiteConfiguration>;
